import React from 'react';
import PropTypes from 'prop-types';

const CircularPreloader = ({ show }) => {
    if (!show) {
        return null;
    }
    return (
        <div className="preloader-wrapper active">
            <div className="spinner-layer spinner-blue">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="gap-patch">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>

            <div className="spinner-layer spinner-red">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="gap-patch">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>

            <div className="spinner-layer spinner-yellow">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="gap-patch">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    );
};

CircularPreloader.propTypes = {
    show: PropTypes.bool,
};

export default CircularPreloader;
